<template>
  <div>
    <div v-if="isLoading" class="spinnerstyle">
      <Spinner />
    </div>
    <!-- new login page design starts from here -->
    <div
      class="mobileLoginPage-main-container relativeclass d-flex justify-content-center"
    >
      <div
        class="copyrights-font d-flex align-items-end justify-content-center mb-3"
      >
        Copyrights &copy; 2024. All rights reserved
      </div>
      <div
        class="mobileLoginPage-container d-flex align-items-center justify-content-center"
      >
        <div>
          <img src="../../assets/mobile-newUI-imgs/login-logo.svg" alt="" />
        </div>
      </div>

      <div class="mobileLogin-inner-container text-center">
        <div class="mobileLoginPage-container-head mt-4">
          Welcome!
        </div>
        <div class="mt-2 mobileLoginPage-container-subhead">
          Welcome to Hire3X Assessment portal. Please enter your details.
        </div>
        <b-row>
          <b-col cols="12">
            <div class="mt-4">
              <label class="mobile-login-label"
                >Name<span class="asterik">*</span></label
              >
              <b-form-input
                v-model.trim="$v.name.$model"
                :class="{ mobileScreenvalidationinputField: $v.name.$error }"
                @keyup.enter="onSave()"
                id="nameInput"
                class="mobileScreen-inputField"
                type="text"
                placeholder="Enter Name"
              ></b-form-input>
              <div
                class="mobileScreen-validationicon"
                v-if="$v.name.$dirty && !$v.name.required"
              >
                Required
              </div>
              <div
                class="mobileScreen-validationicon"
                v-if="$v.name.required && !$v.name.alpha"
              >
                Enter a Valid Name
              </div>
              <div
                class="mobileScreen-validationicon"
                v-if="!$v.name.maxLength"
              >
                It should not exceed 64 characters
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <div class="topDiv mt-4">
              <label class="mobile-login-label"
                >Email<span class="asterik">*</span></label
              >
              <b-form-input
                v-model.trim="$v.email.$model"
                :class="{ mobileScreenvalidationinputField: $v.email.$error }"
                @keyup.enter="onSave()"
                id="emailInput"
                class="mobileScreen-inputField"
                type="text"
                placeholder="Enter Email ID"
              ></b-form-input>
              <div
                class="mobileScreen-validationicon"
                v-if="$v.email.$dirty && !$v.email.required"
              >
                Required
              </div>
              <div
                class="mobileScreen-validationicon"
                v-if="$v.email.required && !$v.email.email"
              >
                Enter a valid Email Id
              </div>
              <div
                class="mobileScreen-validationicon"
                v-if="!$v.email.maxLength"
              >
                It should not exceed 64 characters
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" class="mt-4">
            <div class="d-flex justify-content-center mt-3 mb-4">
              <b-button
                style="border-radius: 8px !important;
    padding: 10px !important;"
                class="mobileScreen-startBtn w-100"
                @click="onSave()"
                >Login
                <img
                  class="ml-2"
                  src="../../assets/mobile-newUI-imgs/login-arrow.svg"
                  alt=""
                />
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
    <b-modal id="mobileLoginAlert" centered hide-footer hide-header class="p-0">
      <div
        class="m-4 d-flex align-items-center justify-content-center flex-column"
      >
        <div>
          <img
            src="../../assets/mobile-newUI-imgs/new-warning-icon.svg"
            alt=""
          />
        </div>

        <div class="mt-4 mobileScreen-modal-head">
          Alert!
        </div>

        <div class="mt-3 mobileScreen-modal-subhead text-center">
          {{ toastMessage }}
        </div>

        <div class="mt-4 mobileScreen-TS-secName text-center w-50">
          <b-button
            class="mobileScreen-startBtn w-100"
            @click="$bvModal.hide('mobileLoginAlert')"
            >Okay</b-button
          >
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import studentLogin from "../../mixins/studentLogin";
export default {
  data() {
    return {
      testMobile: true,
    };
  },
  mixins: [studentLogin],
  created() {
    this.getToken();
  },
  methods: {
    //   async getdata() {
    //     const res = await tokenForUserMobileLogin();
    //     if (res.status == 200) {
    //       // console.log(res)
    //       localStorage.setItem("vue_token", res.data.token);
    //       localStorage.setItem("uuid", res.data.userid);
    //       this.getMobileVerification();
    //     }
    //   },
    //   async getMobileVerification() {
    //     candidateSystemCheck(`?uuid=${this.$route.query.uuid}`).catch((err) => {
    //       if (err.response.status == 409) {
    //         this.testMobile = false;
    //       }
    //     });
    //   },
  },
};
</script>

<style scoped>
.btn-centre {
  margin-top: 10%;
}
</style>
