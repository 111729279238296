import {
  getSysToken,
  getUserTokenBypass,
  getCandidateLogin,
  getIds,
  verifyUser,
} from "../apiFunction.js";
import { mapGetters } from "vuex";
import { required, email, maxLength, helpers } from "vuelidate/lib/validators";
import jwt_decode from "jwt-decode";
import Spinner from "../components/fixedcomponents/Spinner.vue";

const studentLogin = {
  name: "StudentLogin",
  data() {
    return {
      name: "",
      email: "",
      toastMessage: "",
      userToken: null,
      testData: [],
      message: false,
      candidates: [],
      isLoading: false,
      userID: null,
    };
  },
  components: {
    Spinner,
  },
  //CASE SENSETIVE TO LOWERCASE
  watch: {
    email() {
      this.email = this.email.toLowerCase();
    },
  },
  validations: {
    name: {
      required,
      maxLength: maxLength(64),
      alpha: helpers.regex("alpha", /^[A-Za-z]+[A-Za-z0-9].*$|[A-Za-z]+\s+[A-Za-z].*$/),
    },
    email: {
      required,
      email,
      maxLength: maxLength(64),
    },
  },
  computed: {
    ...mapGetters("sectionVUEX", {
      testDetails: "getTestDetails",
      systemCheck : "getSystemCheck",
      uuid :"getUUID"
    }),
  },
  mounted() {
    // Get the input field
    let input = document.getElementById("emailInput");
    let input1 = document.getElementById("nameInput");

    // Execute a function when the user presses a key on the keyboard
    input.addEventListener("keypress", function(event) {
      // If the user presses the "Enter" key on the keyboard
      if (event.key === "Enter") {
        // Cancel the default action, if needed
        // event.preventDefault();
        // Trigger the button element with a click
        document.getElementById("cont-btn").click();
      }
    });
    input1.addEventListener("keypress", function(event) {
      if (event.key === "Enter") {
        document.getElementById("cont-btn").click();
      }
    });
  },
  methods: {
    onSave() {
      console.log("catch error");

      this.$v.$touch();
      this.message = false;
      if (!this.$v.$invalid) {
        this.isLoading = true;
        let data = {
          firstName: this.name,
          email: this.email,
          enabled: true,
        };

        verifyUser(data).then((res) => {
          if (res.status == 200) {
            console.log(res);
            this.userID = res.data.data[0].ID;
            this.$store.commit("sectionVUEX/saveUserDetails", data);
            this.$store.commit("sectionVUEX/saveUUID", this.$route.query.uuid);
            getUserTokenBypass(this.email)
              .then(async (res) => {
                localStorage.setItem("vue_token", res.data.token);
                await this.getTestDetails();
                this.isLoading = false;
              })
              .catch((err) => {
                console.log("ERROR on createUser ----" + err);
              });
          }
        });
      }
    },
    goToTestDetails() {
      localStorage.setItem("isLoggedIn", true);
      this.$router.replace({
        path: "/details",
        query: {
          uuid: this.$route.query.uuid,
        },
      });
    },
    async getTestDetails() {
      try {
        localStorage.removeItem("simpCandidState");
        const res = await getIds(this.$route.query.uuid);
        if (res.status == 200) {
          let testID = res.data.data.testID;
          let topicID = res.data.data.topicID;
          let groupID = res.data.data.groupID;
          this.$store.commit("sectionVUEX/saveUserID", this.userToken.sub);
          const res1 = await getCandidateLogin(
            topicID,
            testID,
            groupID,
            this.$route.query.uuid,
            this.userID,
            this.email
          );
          if (res1.status == 200) {
            console.log(res1,"res1")
            this.testData = {};
            this.testData = res1.data.data.testID;
            this.testData.config = res1.data.data.configID;
            this.testData.testID = testID;
            this.testData.topicID = topicID;
            this.testData.groupID = res.data.data.groupID;
            this.testData.topicName = res1.data.data.topicName;
            this.testData.attemptNo = res1.data.data.attemptNo;
            this.testData.jobID=res1.data.data.jobID;
            this.testData.customerName=res1.data.data.customerName;
            this.testData.jobName=res1.data.data.jobName;
            this.$store.commit("sectionVUEX/saveTestDetails", this.testData);
            this.$store.commit(
              "sectionVUEX/savelicensekey",
              res1.data.data.licenseKey
            );
            this.goToTestDetails();
          } else if (res1.status == 203) {
            let width = window.innerWidth;

            if (width < 820) {
              this.toastMessage = res1.data.data.Detail;
              console.log(this.toastMessage);
              this.$bvModal.show("mobileLoginAlert");
            } else {
              this.toastMessage = res1.data.data.Detail;
              console.log(this.toastMessage);
              this.$bvModal.show("expirytestmodalLap");
            }
          } 
        } 
      } catch (error) {
        let width = window.innerWidth;

        if (width < 820) {
          this.toastMessage = error.response.data.data.Detail;
          console.log(this.toastMessage);
          this.$bvModal.show("mobileLoginAlert");
        } else {
          this.toastMessage = error.response.data.data.Detail;

          console.log(this.toastMessage);
          this.$bvModal.show("expirytestmodalLap");
        }
      }
    },

    async getToken() {
      try {
        const res = await getSysToken();
        localStorage.setItem("KEYCLOAK_IDENTITY_LEGACY", res.data.token);
        this.userToken = jwt_decode(res.data.token);
      } catch (error) {
        console.log(error);
      }
    },
  },

  created: function() {
    localStorage.clear();
    this.getToken();
    this.$store.commit("testVUEX/clearSelectedSection",{})
    this.$store.commit("testVUEX/savetotalanswered",{})

  },
};
export default studentLogin;
